
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as formulaireKuOFtmtrABMeta } from "/builds/9612691896/hydraclic-v2/nuxt/pages/administration/acteurs/[k]/formulaire.vue?macro=true";
import { default as indexYJgkm8SclCMeta } from "/builds/9612691896/hydraclic-v2/nuxt/pages/administration/acteurs/[k]/index.vue?macro=true";
import { default as _91k_93dxGpGcD9AdMeta } from "/builds/9612691896/hydraclic-v2/nuxt/pages/administration/acteurs/[k].vue?macro=true";
import { default as acteursB1RNUKB4pcMeta } from "/builds/9612691896/hydraclic-v2/nuxt/pages/administration/acteurs.vue?macro=true";
import { default as configurationKoXyk0qkzBMeta } from "/builds/9612691896/hydraclic-v2/nuxt/pages/administration/configuration.vue?macro=true";
import { default as formulaireMGPqXi8EESMeta } from "/builds/9612691896/hydraclic-v2/nuxt/pages/administration/territoires/[k]/formulaire.vue?macro=true";
import { default as indexmx5V9DyZSgMeta } from "/builds/9612691896/hydraclic-v2/nuxt/pages/administration/territoires/[k]/index.vue?macro=true";
import { default as _91k_93QWv3Hl8DByMeta } from "/builds/9612691896/hydraclic-v2/nuxt/pages/administration/territoires/[k].vue?macro=true";
import { default as territoiresAekRReHkvYMeta } from "/builds/9612691896/hydraclic-v2/nuxt/pages/administration/territoires.vue?macro=true";
import { default as utilisateurs_45permissionsSXRuOAgZmQMeta } from "/builds/9612691896/hydraclic-v2/nuxt/pages/administration/utilisateurs-permissions.vue?macro=true";
import { default as formulairewEkfws1vytMeta } from "/builds/9612691896/hydraclic-v2/nuxt/pages/administration/utilisateurs/[k]/formulaire.vue?macro=true";
import { default as indexqRIZCHtjsOMeta } from "/builds/9612691896/hydraclic-v2/nuxt/pages/administration/utilisateurs/[k]/index.vue?macro=true";
import { default as _91k_93cDfknQyLRWMeta } from "/builds/9612691896/hydraclic-v2/nuxt/pages/administration/utilisateurs/[k].vue?macro=true";
import { default as utilisateursWHZwlVSEIbMeta } from "/builds/9612691896/hydraclic-v2/nuxt/pages/administration/utilisateurs.vue?macro=true";
import { default as indexyCtuMgV5soMeta } from "/builds/9612691896/hydraclic-v2/nuxt/pages/connexion/index.vue?macro=true";
import { default as _91token_93kG9sdJ9ORLMeta } from "/builds/9612691896/hydraclic-v2/nuxt/pages/connexion/mot-de-passe-oublie/[token].vue?macro=true";
import { default as indexO9AX5ItVY9Meta } from "/builds/9612691896/hydraclic-v2/nuxt/pages/connexion/mot-de-passe-oublie/index.vue?macro=true";
import { default as mot_45de_45passe_45oublie3HlHmWwRy1Meta } from "/builds/9612691896/hydraclic-v2/nuxt/pages/connexion/mot-de-passe-oublie.vue?macro=true";
import { default as contactXf0EASKRG2Meta } from "/builds/9612691896/hydraclic-v2/nuxt/pages/contact.vue?macro=true";
import { default as donnees_45personnelles1RvIMeEY8iMeta } from "/builds/9612691896/hydraclic-v2/nuxt/pages/donnees-personnelles.vue?macro=true";
import { default as _91k_93w0HL3T3ybnMeta } from "/builds/9612691896/hydraclic-v2/nuxt/pages/download/[k].vue?macro=true";
import { default as erreuro6dUPc9JQXMeta } from "/builds/9612691896/hydraclic-v2/nuxt/pages/erreur.vue?macro=true";
import { default as formulairexRQejmwjEyMeta } from "/builds/9612691896/hydraclic-v2/nuxt/pages/gestion/controles-simultanes/[k]/formulaire.vue?macro=true";
import { default as index6xUbEcKQhcMeta } from "/builds/9612691896/hydraclic-v2/nuxt/pages/gestion/controles-simultanes/[k]/index.vue?macro=true";
import { default as _91k_931vauYro6dRMeta } from "/builds/9612691896/hydraclic-v2/nuxt/pages/gestion/controles-simultanes/[k].vue?macro=true";
import { default as controles_45simultanespdaifu4QiuMeta } from "/builds/9612691896/hydraclic-v2/nuxt/pages/gestion/controles-simultanes.vue?macro=true";
import { default as formulaire3bHrdayTEBMeta } from "/builds/9612691896/hydraclic-v2/nuxt/pages/gestion/envoi-de-mail/[k]/formulaire.vue?macro=true";
import { default as indexWyeF944eJNMeta } from "/builds/9612691896/hydraclic-v2/nuxt/pages/gestion/envoi-de-mail/[k]/index.vue?macro=true";
import { default as _91k_93rps7BPSTFzMeta } from "/builds/9612691896/hydraclic-v2/nuxt/pages/gestion/envoi-de-mail/[k].vue?macro=true";
import { default as envoi_45de_45mailUq8AwXb7bgMeta } from "/builds/9612691896/hydraclic-v2/nuxt/pages/gestion/envoi-de-mail.vue?macro=true";
import { default as historiqueP0lQMquK3lMeta } from "/builds/9612691896/hydraclic-v2/nuxt/pages/gestion/historique.vue?macro=true";
import { default as formulairej31lQyfoLWMeta } from "/builds/9612691896/hydraclic-v2/nuxt/pages/gestion/import/[k]/formulaire.vue?macro=true";
import { default as index5N6KxEdT0kMeta } from "/builds/9612691896/hydraclic-v2/nuxt/pages/gestion/import/[k]/index.vue?macro=true";
import { default as _91k_93cDnACoD6uRMeta } from "/builds/9612691896/hydraclic-v2/nuxt/pages/gestion/import/[k].vue?macro=true";
import { default as importXWiPPwGnBiMeta } from "/builds/9612691896/hydraclic-v2/nuxt/pages/gestion/import.vue?macro=true";
import { default as formulaire4g2GlyTLgpMeta } from "/builds/9612691896/hydraclic-v2/nuxt/pages/gestion/indisponibilites-programmees/[k]/formulaire.vue?macro=true";
import { default as indexi5otUOXB2gMeta } from "/builds/9612691896/hydraclic-v2/nuxt/pages/gestion/indisponibilites-programmees/[k]/index.vue?macro=true";
import { default as _91k_93pYamj9wwsLMeta } from "/builds/9612691896/hydraclic-v2/nuxt/pages/gestion/indisponibilites-programmees/[k].vue?macro=true";
import { default as indisponibilites_45programmeesYq9xyDUEXaMeta } from "/builds/9612691896/hydraclic-v2/nuxt/pages/gestion/indisponibilites-programmees.vue?macro=true";
import { default as formulaire5cozp2j9kEMeta } from "/builds/9612691896/hydraclic-v2/nuxt/pages/gestion/pei/[k]/formulaire.vue?macro=true";
import { default as indexVUSTBUDgGXMeta } from "/builds/9612691896/hydraclic-v2/nuxt/pages/gestion/pei/[k]/index.vue?macro=true";
import { default as _91k_93m6rd87FwRAMeta } from "/builds/9612691896/hydraclic-v2/nuxt/pages/gestion/pei/[k].vue?macro=true";
import { default as peiaL1e8P55YFMeta } from "/builds/9612691896/hydraclic-v2/nuxt/pages/gestion/pei.vue?macro=true";
import { default as formulairemnSzvZdVVcMeta } from "/builds/9612691896/hydraclic-v2/nuxt/pages/gestion/tournees/[k]/formulaire.vue?macro=true";
import { default as indexajfy7SeYw3Meta } from "/builds/9612691896/hydraclic-v2/nuxt/pages/gestion/tournees/[k]/index.vue?macro=true";
import { default as _91k_93nSw6LlnugPMeta } from "/builds/9612691896/hydraclic-v2/nuxt/pages/gestion/tournees/[k].vue?macro=true";
import { default as tourneesI3g8OxGiBgMeta } from "/builds/9612691896/hydraclic-v2/nuxt/pages/gestion/tournees.vue?macro=true";
import { default as index7K2lqdSdDOMeta } from "/builds/9612691896/hydraclic-v2/nuxt/pages/index.vue?macro=true";
import { default as indexRYEIM7XQytMeta } from "/builds/9612691896/hydraclic-v2/nuxt/pages/profil/index.vue?macro=true";
import { default as profil3uFHIBgUPxMeta } from "/builds/9612691896/hydraclic-v2/nuxt/pages/profil.vue?macro=true";
import { default as ressourcesvxJuHPNX6ZMeta } from "/builds/9612691896/hydraclic-v2/nuxt/pages/ressources.vue?macro=true";
import { default as tableau_45de_45bordM7vPF8fGHNMeta } from "/builds/9612691896/hydraclic-v2/nuxt/pages/tableau-de-bord.vue?macro=true";
export default [
  {
    name: "administration-acteurs",
    path: "/administration/acteurs",
    meta: acteursB1RNUKB4pcMeta || {},
    component: () => import("/builds/9612691896/hydraclic-v2/nuxt/pages/administration/acteurs.vue"),
    children: [
  {
    name: _91k_93dxGpGcD9AdMeta?.name,
    path: ":k()",
    component: () => import("/builds/9612691896/hydraclic-v2/nuxt/pages/administration/acteurs/[k].vue"),
    children: [
  {
    name: "administration-acteurs-k-formulaire",
    path: "formulaire",
    component: () => import("/builds/9612691896/hydraclic-v2/nuxt/pages/administration/acteurs/[k]/formulaire.vue")
  },
  {
    name: "administration-acteurs-k",
    path: "",
    component: () => import("/builds/9612691896/hydraclic-v2/nuxt/pages/administration/acteurs/[k]/index.vue")
  }
]
  }
]
  },
  {
    name: "administration-configuration",
    path: "/administration/configuration",
    meta: configurationKoXyk0qkzBMeta || {},
    component: () => import("/builds/9612691896/hydraclic-v2/nuxt/pages/administration/configuration.vue")
  },
  {
    name: "administration-territoires",
    path: "/administration/territoires",
    meta: territoiresAekRReHkvYMeta || {},
    component: () => import("/builds/9612691896/hydraclic-v2/nuxt/pages/administration/territoires.vue"),
    children: [
  {
    name: _91k_93QWv3Hl8DByMeta?.name,
    path: ":k()",
    component: () => import("/builds/9612691896/hydraclic-v2/nuxt/pages/administration/territoires/[k].vue"),
    children: [
  {
    name: "administration-territoires-k-formulaire",
    path: "formulaire",
    component: () => import("/builds/9612691896/hydraclic-v2/nuxt/pages/administration/territoires/[k]/formulaire.vue")
  },
  {
    name: "administration-territoires-k",
    path: "",
    component: () => import("/builds/9612691896/hydraclic-v2/nuxt/pages/administration/territoires/[k]/index.vue")
  }
]
  }
]
  },
  {
    name: "administration-utilisateurs-permissions",
    path: "/administration/utilisateurs-permissions",
    meta: utilisateurs_45permissionsSXRuOAgZmQMeta || {},
    component: () => import("/builds/9612691896/hydraclic-v2/nuxt/pages/administration/utilisateurs-permissions.vue")
  },
  {
    name: "administration-utilisateurs",
    path: "/administration/utilisateurs",
    meta: utilisateursWHZwlVSEIbMeta || {},
    component: () => import("/builds/9612691896/hydraclic-v2/nuxt/pages/administration/utilisateurs.vue"),
    children: [
  {
    name: _91k_93cDfknQyLRWMeta?.name,
    path: ":k()",
    component: () => import("/builds/9612691896/hydraclic-v2/nuxt/pages/administration/utilisateurs/[k].vue"),
    children: [
  {
    name: "administration-utilisateurs-k-formulaire",
    path: "formulaire",
    component: () => import("/builds/9612691896/hydraclic-v2/nuxt/pages/administration/utilisateurs/[k]/formulaire.vue")
  },
  {
    name: "administration-utilisateurs-k",
    path: "",
    component: () => import("/builds/9612691896/hydraclic-v2/nuxt/pages/administration/utilisateurs/[k]/index.vue")
  }
]
  }
]
  },
  {
    name: "connexion",
    path: "/connexion",
    meta: indexyCtuMgV5soMeta || {},
    component: () => import("/builds/9612691896/hydraclic-v2/nuxt/pages/connexion/index.vue")
  },
  {
    name: mot_45de_45passe_45oublie3HlHmWwRy1Meta?.name,
    path: "/connexion/mot-de-passe-oublie",
    meta: mot_45de_45passe_45oublie3HlHmWwRy1Meta || {},
    component: () => import("/builds/9612691896/hydraclic-v2/nuxt/pages/connexion/mot-de-passe-oublie.vue"),
    children: [
  {
    name: "connexion-mot-de-passe-oublie-token",
    path: ":token()",
    meta: _91token_93kG9sdJ9ORLMeta || {},
    component: () => import("/builds/9612691896/hydraclic-v2/nuxt/pages/connexion/mot-de-passe-oublie/[token].vue")
  },
  {
    name: "connexion-mot-de-passe-oublie",
    path: "",
    meta: indexO9AX5ItVY9Meta || {},
    component: () => import("/builds/9612691896/hydraclic-v2/nuxt/pages/connexion/mot-de-passe-oublie/index.vue")
  }
]
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/builds/9612691896/hydraclic-v2/nuxt/pages/contact.vue")
  },
  {
    name: "donnees-personnelles",
    path: "/donnees-personnelles",
    component: () => import("/builds/9612691896/hydraclic-v2/nuxt/pages/donnees-personnelles.vue")
  },
  {
    name: "download-k",
    path: "/download/:k()",
    meta: _91k_93w0HL3T3ybnMeta || {},
    component: () => import("/builds/9612691896/hydraclic-v2/nuxt/pages/download/[k].vue")
  },
  {
    name: "erreur",
    path: "/erreur",
    meta: erreuro6dUPc9JQXMeta || {},
    component: () => import("/builds/9612691896/hydraclic-v2/nuxt/pages/erreur.vue")
  },
  {
    name: "gestion-controles-simultanes",
    path: "/gestion/controles-simultanes",
    meta: controles_45simultanespdaifu4QiuMeta || {},
    component: () => import("/builds/9612691896/hydraclic-v2/nuxt/pages/gestion/controles-simultanes.vue"),
    children: [
  {
    name: _91k_931vauYro6dRMeta?.name,
    path: ":k()",
    component: () => import("/builds/9612691896/hydraclic-v2/nuxt/pages/gestion/controles-simultanes/[k].vue"),
    children: [
  {
    name: "gestion-controles-simultanes-k-formulaire",
    path: "formulaire",
    component: () => import("/builds/9612691896/hydraclic-v2/nuxt/pages/gestion/controles-simultanes/[k]/formulaire.vue")
  },
  {
    name: "gestion-controles-simultanes-k",
    path: "",
    component: () => import("/builds/9612691896/hydraclic-v2/nuxt/pages/gestion/controles-simultanes/[k]/index.vue")
  }
]
  }
]
  },
  {
    name: "gestion-envoi-de-mail",
    path: "/gestion/envoi-de-mail",
    meta: envoi_45de_45mailUq8AwXb7bgMeta || {},
    component: () => import("/builds/9612691896/hydraclic-v2/nuxt/pages/gestion/envoi-de-mail.vue"),
    children: [
  {
    name: _91k_93rps7BPSTFzMeta?.name,
    path: ":k()",
    component: () => import("/builds/9612691896/hydraclic-v2/nuxt/pages/gestion/envoi-de-mail/[k].vue"),
    children: [
  {
    name: "gestion-envoi-de-mail-k-formulaire",
    path: "formulaire",
    meta: formulaire3bHrdayTEBMeta || {},
    component: () => import("/builds/9612691896/hydraclic-v2/nuxt/pages/gestion/envoi-de-mail/[k]/formulaire.vue")
  },
  {
    name: "gestion-envoi-de-mail-k",
    path: "",
    component: () => import("/builds/9612691896/hydraclic-v2/nuxt/pages/gestion/envoi-de-mail/[k]/index.vue")
  }
]
  }
]
  },
  {
    name: "gestion-historique",
    path: "/gestion/historique",
    meta: historiqueP0lQMquK3lMeta || {},
    component: () => import("/builds/9612691896/hydraclic-v2/nuxt/pages/gestion/historique.vue")
  },
  {
    name: "gestion-import",
    path: "/gestion/import",
    meta: importXWiPPwGnBiMeta || {},
    component: () => import("/builds/9612691896/hydraclic-v2/nuxt/pages/gestion/import.vue"),
    children: [
  {
    name: _91k_93cDnACoD6uRMeta?.name,
    path: ":k()",
    component: () => import("/builds/9612691896/hydraclic-v2/nuxt/pages/gestion/import/[k].vue"),
    children: [
  {
    name: "gestion-import-k-formulaire",
    path: "formulaire",
    meta: formulairej31lQyfoLWMeta || {},
    component: () => import("/builds/9612691896/hydraclic-v2/nuxt/pages/gestion/import/[k]/formulaire.vue")
  },
  {
    name: "gestion-import-k",
    path: "",
    meta: index5N6KxEdT0kMeta || {},
    component: () => import("/builds/9612691896/hydraclic-v2/nuxt/pages/gestion/import/[k]/index.vue")
  }
]
  }
]
  },
  {
    name: "gestion-indisponibilites-programmees",
    path: "/gestion/indisponibilites-programmees",
    component: () => import("/builds/9612691896/hydraclic-v2/nuxt/pages/gestion/indisponibilites-programmees.vue"),
    children: [
  {
    name: _91k_93pYamj9wwsLMeta?.name,
    path: ":k()",
    component: () => import("/builds/9612691896/hydraclic-v2/nuxt/pages/gestion/indisponibilites-programmees/[k].vue"),
    children: [
  {
    name: "gestion-indisponibilites-programmees-k-formulaire",
    path: "formulaire",
    component: () => import("/builds/9612691896/hydraclic-v2/nuxt/pages/gestion/indisponibilites-programmees/[k]/formulaire.vue")
  },
  {
    name: "gestion-indisponibilites-programmees-k",
    path: "",
    component: () => import("/builds/9612691896/hydraclic-v2/nuxt/pages/gestion/indisponibilites-programmees/[k]/index.vue")
  }
]
  }
]
  },
  {
    name: "gestion-pei",
    path: "/gestion/pei",
    meta: peiaL1e8P55YFMeta || {},
    alias: ["/gestion/carte"],
    component: () => import("/builds/9612691896/hydraclic-v2/nuxt/pages/gestion/pei.vue"),
    children: [
  {
    name: _91k_93m6rd87FwRAMeta?.name,
    path: ":k()",
    component: () => import("/builds/9612691896/hydraclic-v2/nuxt/pages/gestion/pei/[k].vue"),
    children: [
  {
    name: "gestion-pei-k-formulaire",
    path: "formulaire",
    component: () => import("/builds/9612691896/hydraclic-v2/nuxt/pages/gestion/pei/[k]/formulaire.vue")
  },
  {
    name: "gestion-pei-k",
    path: "",
    component: () => import("/builds/9612691896/hydraclic-v2/nuxt/pages/gestion/pei/[k]/index.vue")
  }
]
  }
]
  },
  {
    name: "gestion-tournees",
    path: "/gestion/tournees",
    meta: tourneesI3g8OxGiBgMeta || {},
    component: () => import("/builds/9612691896/hydraclic-v2/nuxt/pages/gestion/tournees.vue"),
    children: [
  {
    name: _91k_93nSw6LlnugPMeta?.name,
    path: ":k()",
    component: () => import("/builds/9612691896/hydraclic-v2/nuxt/pages/gestion/tournees/[k].vue"),
    children: [
  {
    name: "gestion-tournees-k-formulaire",
    path: "formulaire",
    component: () => import("/builds/9612691896/hydraclic-v2/nuxt/pages/gestion/tournees/[k]/formulaire.vue")
  },
  {
    name: "gestion-tournees-k",
    path: "",
    component: () => import("/builds/9612691896/hydraclic-v2/nuxt/pages/gestion/tournees/[k]/index.vue")
  }
]
  }
]
  },
  {
    name: "index",
    path: "/",
    component: () => import("/builds/9612691896/hydraclic-v2/nuxt/pages/index.vue")
  },
  {
    name: profil3uFHIBgUPxMeta?.name,
    path: "/profil",
    meta: profil3uFHIBgUPxMeta || {},
    component: () => import("/builds/9612691896/hydraclic-v2/nuxt/pages/profil.vue"),
    children: [
  {
    name: "profil",
    path: "",
    component: () => import("/builds/9612691896/hydraclic-v2/nuxt/pages/profil/index.vue")
  }
]
  },
  {
    name: "ressources",
    path: "/ressources",
    component: () => import("/builds/9612691896/hydraclic-v2/nuxt/pages/ressources.vue")
  },
  {
    name: "tableau-de-bord",
    path: "/tableau-de-bord",
    meta: tableau_45de_45bordM7vPF8fGHNMeta || {},
    component: () => import("/builds/9612691896/hydraclic-v2/nuxt/pages/tableau-de-bord.vue")
  }
]